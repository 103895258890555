.flex {
    display: flex;
}

.flex__center {
    justify-content: center;
    align-items: center;
}

.flex__align {
    align-items: center;
}

.flex__space {
    justify-content: space-between;
}

.flex__wrap {
    flex-wrap: wrap;
}

.flex__1 {
    flex: 1;
}
.flex__2 {
    flex: 2;
}

.flex__reverse {
    flex-direction: row-reverse;
}
