.switch {
    position: relative;
    display: inline-flex;
    width: $base * 4;
    height: $base * 2;
}

.toggle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c-border;
    border: 2px solid darken($c-border, 5%);
    border-radius: $base * 0.5;
}

.toggle:before {
    content: "";
    position: absolute;
    height: 100%;
    width: $base * 1.9;
    left: 0;
    bottom: 0;
    background-color: darken($c-border, 10%);
    border-radius: $base * 0.3;
    transition: background-color 300ms $ease-slow, transform 300ms $ease-card;
}

.toggle__on {
    background-color: $c-green;
    border: 2px solid darken($c-green, 5%);
    &:before {
        background-color: darken($c-green, 5%);
        transform: translate3d(100%, 0, 0);
    }
}

/* Rounded sliders */
.toggle.round {
    border-radius: 34px;
}

.toggle.round:before {
    border-radius: 50%;
}
