.log__container {
    margin-top: $base;

    max-height: $base * 15;
    min-height: $base * 15;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: $base;
        border-radius: $base;
        background-color: lighten($c-border, 5%);
    }
    &::-webkit-scrollbar-track {
        width: $base;
        //background-color: $c-main;
    }
    &::-webkit-scrollbar-thumb {
        width: $base;
        border-radius: $base;
        background-color: darken($c-border, 0%);
    }
}

.log {
    padding: $base * 0.5 0;
    border-bottom: 2px solid $c-border;
}
