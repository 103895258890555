.row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.col__12 {
    flex-grow: 0;
    max-width: grid(12);
    flex-basis: grid(12);
}

.col__8 {
    flex-grow: 0;
    max-width: grid(8);
    flex-basis: grid(8);
}

.col__6 {
    flex-grow: 0;
    max-width: grid(6);
    flex-basis: grid(6);
}

.col__4 {
    flex-grow: 0;
    max-width: grid(4);
    flex-basis: grid(4);
}

.col__3 {
    flex-grow: 0;
    max-width: grid(3);
    flex-basis: grid(3);
}

/*
*  SIZE MEDIUM 
*/

.col_m__4 {
    @include mq(m) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_m__6 {
    @include mq(m) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.col_m__8 {
    @include mq(m) {
        flex-grow: 0;
        max-width: grid(8);
        flex-basis: grid(8);
    }
}

/*
*  SIZE LARGE 
*/

.col_l__4 {
    @include mq(l) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_l__6 {
    @include mq(l) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

/*
*  EXTRA LARGE
*/

.col_xl__3 {
    @include mq(xl) {
        flex-grow: 0;
        max-width: grid(3);
        flex-basis: grid(3);
    }
}
