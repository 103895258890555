$font-main: "Open Sans";
$font-family: $font-main, Helvetica Neue, Arial, sans-serif !default;
$root-font-size: 16px;

$c-white: #ffffff;

$c-midnight: #2c3e50;
$c-asphalt: #34495e;

$c-cloud: #ecf0f1;
$c-silver: #bdc3c7;

// $c-bg: #061525;
$c-bg: #f9fafc;
$c-blue: #303e4d;
$c-green: #1dd1a1;
$c-red: #e74c3c;
$c-yellow: #ffdd59;
$c-orange: #ffc048;
$c-main: #0070e0;

// - Backgrounds
$c-gray-l: #f1f5f8;
$c-gray: #dae1e7;
$c-gray-d: #b8c2cc;
// - text
$c-text: #22292f;
$c-text-gray: #b8c2cc;

// - border
$c-border: darken(#f1f5f8, 3%);

// - Shadow
// $shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15), 0 8px 8px 0 rgba(0, 0, 0, 0.05);
$shadow: 0 6px 4px $c-gray;
$shadow-secondary: 0 0 4px 0 rgba(0, 0, 0, 0.15),
    0 8px 8px 0 rgba(0, 0, 0, 0.05);
// $shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
// $shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
// $shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
// $shadow-menu: 0 2px 4px rgba(2, 1, 1, 0.2);

$base: 0.8rem;
$base-gutter: $base * 2;

$mq--s: 480px;
$mq--ss: 580px;
$mq--sm: 768px;
$mq--m: 1024px;
$mq--l: 1280px;
$mq--xl: 1440px;
$mq--xxl: 1920px;

$ease-scale: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-card: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-slow: cubic-bezier(0.19, 1, 0.22, 1);
