.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(#26374d, 0.9);
}

.modal__close {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}

.modal__center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__scroll {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: $base * 6;
    padding-bottom: $base * 6;
}

.modal__slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    position: relative;
    padding: $base * 2;
    border-radius: $base * 0.5;
    background-color: $c-white;
    border: 1px solid $c-border;
    box-shadow: $shadow-secondary;
    width: $base * 40;
}

.modal__container-m {
    width: $base * 45;
}

.modal__container-l {
    width: $base * 55;
}
