.mr__s {
    margin-right: $base * 0.5;
}

.mb__m {
    margin-bottom: $base * 2;
}

.ml__s {
    margin-left: $base * 0.5;
}

.ml__m {
    margin-left: $base * 1;
}

.mr__m {
    margin-right: $base * 1;
}

.mr__l {
    margin-left: $base * 1;
}

.mt__m {
    margin-top: $base * 2;
}

.mt__s {
    margin-top: $base * 1;
}

.mt__m {
    margin-top: $base * 2;
}
