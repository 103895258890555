.pt__s {
    padding-top: $base * 1;
}

.pt__m {
    padding-top: $base * 2;
}

.pt__xl {
    padding-top: $base * 5;
}

.pb__s {
    padding-bottom: $base * 1;
}

.pb__m {
    padding-bottom: $base * 2;
}

.pb__xl {
    padding-bottom: $base * 5;
}

.pb__xs {
    padding-bottom: $base * 0.5;
}

.pl__s {
    padding-left: $base * 1;
}

.pr__s {
    padding-right: $base * 1;
}

.pr__m {
    padding-right: $base * 2;
}
