.fw__b {
    font-weight: 600;
}

.fw__n {
    font-weight: 400;
}

.fs__xs {
    font-size: $base * 1;
}

.fs__s {
    font-size: $base * 1.1;
}

.fs__xl {
    font-size: $base * 4.2;
}

.center {
    text-align: center;
}

.text__wrap {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
