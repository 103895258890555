.light {
    display: inline-block;
    height: $base * 1;
    width: $base * 1;
    border-radius: 50%;
    background-color: $c-border;
    border: 2px solid darken($c-border, 10%);
}

.light__on {
    background-color: $c-green;
    border: 2px solid darken($c-green, 10%);
}

.light__off {
    background-color: lighten($c-red, 10);
    border: 2px solid darken($c-red, 0%);
}

.light__slide {
    position: relative;
    display: inline-block;
    height: $base * 1.5;
    width: $base * 1.5;
    background-color: $c-white;
    border: 2px solid $c-gray-d;
    will-change: transform;
    transition: transform 250ms $ease-scale;
}

.light__slide__on {
    border: $base * 0.2 solid lighten($c-main, 10%);
    background-color: $c-main;
    transform: scale(1.3);
}
