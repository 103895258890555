.container {
    width: 100%;
    padding-left: $base * 2;
    padding-right: $base * 2;
    @include mq(m) {
        padding-left: $base * 4;
        padding-right: $base * 4;
    }
    @include mq(xl) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }
}

.container__wrap {
    width: $base * 55;
}
