input,
textarea,
select {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    font-size: inherit;
    font-family: inherit;
    overflow: visible;
    line-height: inherit;
    box-shadow: none;
    &:focus,
    &:active {
        outline: none;
    }
}

textarea {
    resize: vertical;
}

.input {
    width: 100%;
    padding: $base * 0.7 $base;
    color: $c-text;
    background-color: $c-white;
    border: 2px solid $c-border;
    border-radius: $base * 0.3;
    font-size: $base * 1.2;
    &::placeholder {
        color: $c-text-gray;
    }
    &:focus {
        border-color: $c-main;
    }
    &--error {
        border-color: $c-red !important;
    }
}

select {
    height: $base * 3;
}

.input__toggle {
    font-weight: 600;
    font-size: $base * 1;
    cursor: pointer;
    will-change: padding, background-color;
    transition: padding 350ms $ease-scale, background-color 450ms $ease-card;
    &:hover {
        padding-top: $base * 0.5;
        padding-bottom: $base * 0.5;
        background-color: $c-border;
    }
}
