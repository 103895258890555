.c__w {
    color: $c_white;
}

.c__sec {
    color: $c-gray-d;
}

.c__g {
    color: $c-green;
}

.c__r {
    color: $c-red;
}

.c__o {
    color: $c-orange;
}
