.full {
    width: 100%;
}

.hidden {
    display: none;
}

.bt {
    border-top: 2px solid $c-border;
}

.bb {
    border-bottom: 2px solid $c-border;
}

.bl {
    border-left: 2px solid $c-border;
}
