button {
    -webkit-appearance: none !important;
    writing-mode: none !important;
    border: none;
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $base * 0.3;
    line-height: $base * 2.5;
    padding: 0 $base * 0.5;
    background-color: lighten($c-main, 20%);
    border: 2px solid lighten($c-main, 15%);
    color: darken($c-main, 15%);
    font-size: $base * 1;
    &__gray {
        background-color: $c-border;
        border: 2px solid darken($c-border, 5%);
        color: darken($c-border, 25%);
    }
    &__disabled {
        background-color: lighten($c-border, 5%);
        border: 2px solid $c-border;
        color: darken($c-border, 10%);
    }

    &__red {
        background-color: $c-red;
        border: 2px solid $c-red;
        color: darken($c-red, 25%);
    }

    &__green {
        background-color: $c-green;
        border: 2px solid $c-green;
        color: darken($c-green, 25%);
    }

    &__border {
        background-color: $c-white;
        border: 2px solid $c-border;
        color: darken($c-border, 20%);
    }
}

.btn__round {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $base * 4;
    width: $base * 4;
    z-index: 5;
    color: darken($c-main, 20%);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $c-main;
        border-radius: 50%;
        z-index: -1;
        border: $base * 0.6 solid lighten($c-main, 10%);
        animation: pulse 1600ms infinite $ease-scale;
    }
}

.btn__fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}

.p_t_r {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
}

.relative {
    position: relative;
}
