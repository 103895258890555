.label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $base * 0.1 $base * 0.8;
    border-radius: $base * 0.4;
    font-size: $base * 0.9;
}

.label__green {
    background-color: $c-green;
    color: darken($c-green, 25%);
}

.label__gray {
    background-color: $c-border;
    color: darken($c-border, 25%);
}
