.list__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $base * 1;
    padding-bottom: $base * 1;
    border-bottom: 2px solid $c-border;
    cursor: pointer;
    transition: padding 350ms $ease-scale, background-color 400ms $ease-slow;
    will-change: padding, background-color;

    &:hover {
        padding-top: $base * 2;
        padding-bottom: $base * 2;
        background-color: $c-white;
    }
}
