.bg__w {
    background-color: $c-white;
}

.bg__g {
    background-color: $c-green;
    color: darken($c-green, 25%);
}

.bg__r {
    background-color: $c-main;
    color: darken($c-main, 25%);
}

.bg__b {
    background-color: $c-blue;
    color: darken($c-blue, 25%);
}

.bg__y {
    background-color: $c-yellow;
    color: darken($c-yellow, 35%);
    border: 2px solid darken($c-yellow, 5%);
}

.bg__o {
    background-color: $c-orange;
    color: darken($c-orange, 35%);
}
