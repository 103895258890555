.menu {
    // position: absolute;
    position: relative;
    background-color: $c-white;
    min-width: $base * 20;
    border-radius: $base * 0.5;
    z-index: 5;
    box-shadow: $shadow-secondary;
    width: 100%;
    overflow: hidden;
}

.menu__wrap {
    max-height: $base * 35;
    overflow-y: scroll;
}

.menu__item {
    padding: $base * 1.2;
    width: 100%;
    text-align: left;
    &:hover {
        background-color: $c-border;
    }
}

.menu__nav {
    padding: $base * 0.5;
    background-color: $c-border;
}

// .menu-user {
//     padding: $base * 0.25 $base * 0.5;
// }

// .menu-active {
//     background-color: $c-border;
// }
