.bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg__loading {
    opacity: 0;
    transition: opacity 1200ms $ease-slow;
}

.bg__loaded {
    opacity: 1;
}

.b__r {
    border-radius: 50%;
}

.img__payment {
    height: 100%;
    max-height: 50vh;
    @include mq(m) {
        width: 100%;
        max-height: 80vh;
    }
}

.img {
    display: inline-flex;
    vertical-align: middle;
    background-color: $c-cloud;
    &__s {
        width: $base * 2.85;
        height: $base * 2.85;
    }
    &__xs {
        width: $base * 2.2;
        height: $base * 2.2;
    }
    &__sm {
        width: $base * 3.7;
        height: $base * 3.7;
    }
    &__m {
        width: $base * 5;
        height: $base * 5;
    }
}
