.card {
    padding: $base;
    position: relative;
    display: block;
    width: 100%;
    border-radius: $base * 0.5;
    background-color: $c-white;
    border: 2px solid $c-border;
}

.card__row {
    flex: 0 0 calc(100% / 3 - 1.6rem);
    margin-bottom: $base * 2;
}
