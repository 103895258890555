.navigation {
    position: relative;
    width: 100%;
    z-index: 5;
    background-color: $c-white;
    border-bottom: 2px solid $c-border;
}

.navigation__height {
    height: $base * 6;
}

.navigation__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
